<script lang="ts">
  import { app } from '../stores'
  export let type: string
</script>

<style>
  /* .bn-notify-notification-status-icon */
  div {
    height: 100%;
    font-size: inherit;
    font-family: inherit;
    width: 1.5em;
  }
</style>

<div
  class="bn-notify-custom bn-notify-notification-status-icon {$app.name ? `bn-notify-${$app.name}` : ''}">
  {#if type === 'hint'}
    <svg
      viewBox="0 0 190 190"
      xmlns="http://www.w3.org/2000/svg"
      id="el_DHAskxC2T">
      <style>
        @-webkit-keyframes kf_el_51c2MS41pY_an_cXFUsKhg3V {
          50% {
            stroke-dasharray: 553;
          }
          0% {
            stroke-dasharray: 553;
          }
          100% {
            stroke-dasharray: 553;
          }
        }
        @keyframes kf_el_51c2MS41pY_an_cXFUsKhg3V {
          50% {
            stroke-dasharray: 553;
          }
          0% {
            stroke-dasharray: 553;
          }
          100% {
            stroke-dasharray: 553;
          }
        }
        @-webkit-keyframes kf_el_51c2MS41pY_an_M-ML-YLcm {
          50% {
            stroke-dashoffset: 553;
          }
          100% {
            stroke-dashoffset: 0;
          }
          0% {
            stroke-dashoffset: 553;
          }
        }
        @keyframes kf_el_51c2MS41pY_an_M-ML-YLcm {
          50% {
            stroke-dashoffset: 553;
          }
          100% {
            stroke-dashoffset: 0;
          }
          0% {
            stroke-dashoffset: 553;
          }
        }
        @-webkit-keyframes kf_el_j5HR_U6Nrp_an_KGzPpGvQb {
          50% {
            opacity: 0;
          }
          56.67% {
            opacity: 1;
          }
          0% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }
        @keyframes kf_el_j5HR_U6Nrp_an_KGzPpGvQb {
          50% {
            opacity: 0;
          }
          56.67% {
            opacity: 1;
          }
          0% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }
        @-webkit-keyframes kf_el_j5HR_U6Nrp_an_al_MjoEv-F {
          50% {
            stroke-dasharray: 39.41;
          }
          0% {
            stroke-dasharray: 39.41;
          }
          100% {
            stroke-dasharray: 39.41;
          }
        }
        @keyframes kf_el_j5HR_U6Nrp_an_al_MjoEv-F {
          50% {
            stroke-dasharray: 39.41;
          }
          0% {
            stroke-dasharray: 39.41;
          }
          100% {
            stroke-dasharray: 39.41;
          }
        }
        @-webkit-keyframes kf_el_j5HR_U6Nrp_an_VsVMmQ1MU {
          50% {
            stroke-dashoffset: 39.41;
          }
          100% {
            stroke-dashoffset: 0;
          }
          0% {
            stroke-dashoffset: 39.41;
          }
        }
        @keyframes kf_el_j5HR_U6Nrp_an_VsVMmQ1MU {
          50% {
            stroke-dashoffset: 39.41;
          }
          100% {
            stroke-dashoffset: 0;
          }
          0% {
            stroke-dashoffset: 39.41;
          }
        }
        @-webkit-keyframes kf_el_TZApOLwXZU_an_dL6-SZLSH {
          50% {
            -webkit-transform: translate(88.50000762939453px, 56.5px)
              scale(0, 0) translate(-88.50000762939453px, -56.5px);
            transform: translate(88.50000762939453px, 56.5px) scale(0, 0)
              translate(-88.50000762939453px, -56.5px);
          }
          83.33% {
            -webkit-transform: translate(88.50000762939453px, 56.5px)
              scale(0, 0) translate(-88.50000762939453px, -56.5px);
            transform: translate(88.50000762939453px, 56.5px) scale(0, 0)
              translate(-88.50000762939453px, -56.5px);
          }
          100% {
            -webkit-transform: translate(88.50000762939453px, 56.5px)
              scale(1, 1) translate(-88.50000762939453px, -56.5px);
            transform: translate(88.50000762939453px, 56.5px) scale(1, 1)
              translate(-88.50000762939453px, -56.5px);
          }
          0% {
            -webkit-transform: translate(88.50000762939453px, 56.5px)
              scale(0, 0) translate(-88.50000762939453px, -56.5px);
            transform: translate(88.50000762939453px, 56.5px) scale(0, 0)
              translate(-88.50000762939453px, -56.5px);
          }
        }
        @keyframes kf_el_TZApOLwXZU_an_dL6-SZLSH {
          50% {
            -webkit-transform: translate(88.50000762939453px, 56.5px)
              scale(0, 0) translate(-88.50000762939453px, -56.5px);
            transform: translate(88.50000762939453px, 56.5px) scale(0, 0)
              translate(-88.50000762939453px, -56.5px);
          }
          83.33% {
            -webkit-transform: translate(88.50000762939453px, 56.5px)
              scale(0, 0) translate(-88.50000762939453px, -56.5px);
            transform: translate(88.50000762939453px, 56.5px) scale(0, 0)
              translate(-88.50000762939453px, -56.5px);
          }
          100% {
            -webkit-transform: translate(88.50000762939453px, 56.5px)
              scale(1, 1) translate(-88.50000762939453px, -56.5px);
            transform: translate(88.50000762939453px, 56.5px) scale(1, 1)
              translate(-88.50000762939453px, -56.5px);
          }
          0% {
            -webkit-transform: translate(88.50000762939453px, 56.5px)
              scale(0, 0) translate(-88.50000762939453px, -56.5px);
            transform: translate(88.50000762939453px, 56.5px) scale(0, 0)
              translate(-88.50000762939453px, -56.5px);
          }
        }
        #el_DHAskxC2T * {
          -webkit-animation-duration: 1s;
          animation-duration: 1s;
          -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
          -webkit-animation-timing-function: cubic-bezier(0, 0, 1, 1);
          animation-timing-function: cubic-bezier(0, 0, 1, 1);
        }
        #el__hILOKhuR3 {
          fill: none;
          -webkit-transform: translate(7px, 7px);
          transform: translate(7px, 7px);
        }
        #el_TZApOLwXZU {
          fill: #979797;
          -webkit-transform: matrix(1, 0, 0, -1, 0, 113);
          transform: matrix(1, 0, 0, -1, 0, 113);
        }
        #el_fIxIrV8WbF {
          stroke: #979797;
          stroke-width: 14;
        }
        #el_TZApOLwXZU_an_dL6-SZLSH {
          -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
          -webkit-animation-name: kf_el_TZApOLwXZU_an_dL6-SZLSH;
          animation-name: kf_el_TZApOLwXZU_an_dL6-SZLSH;
          -webkit-animation-timing-function: cubic-bezier(0, 0, 1, 1);
          animation-timing-function: cubic-bezier(0, 0, 1, 1);
        }
        #el_j5HR_U6Nrp {
          -webkit-animation-fill-mode: forwards, forwards, forwards;
          animation-fill-mode: forwards, forwards, forwards;
          -webkit-animation-name: kf_el_j5HR_U6Nrp_an_VsVMmQ1MU,
            kf_el_j5HR_U6Nrp_an_al_MjoEv-F, kf_el_j5HR_U6Nrp_an_KGzPpGvQb;
          animation-name: kf_el_j5HR_U6Nrp_an_VsVMmQ1MU,
            kf_el_j5HR_U6Nrp_an_al_MjoEv-F, kf_el_j5HR_U6Nrp_an_KGzPpGvQb;
          -webkit-animation-timing-function: cubic-bezier(0, 0, 1, 1),
            cubic-bezier(0, 0, 1, 1), cubic-bezier(0, 0, 1, 1);
          animation-timing-function: cubic-bezier(0, 0, 1, 1),
            cubic-bezier(0, 0, 1, 1), cubic-bezier(0, 0, 1, 1);
        }
        #el_51c2MS41pY {
          -webkit-animation-fill-mode: forwards, forwards;
          animation-fill-mode: forwards, forwards;
          -webkit-animation-name: kf_el_51c2MS41pY_an_M-ML-YLcm,
            kf_el_51c2MS41pY_an_cXFUsKhg3V;
          animation-name: kf_el_51c2MS41pY_an_M-ML-YLcm,
            kf_el_51c2MS41pY_an_cXFUsKhg3V;
          -webkit-animation-timing-function: cubic-bezier(0, 0, 1, 1),
            cubic-bezier(0, 0, 1, 1);
          animation-timing-function: cubic-bezier(0, 0, 1, 1),
            cubic-bezier(0, 0, 1, 1);
        }
      </style>
      <g fill-rule="evenodd" id="el__hILOKhuR3">
        <g
          id="el_TZApOLwXZU_an_dL6-SZLSH"
          data-animator-group="true"
          data-animator-type="2">
          <circle cx="88.5" cy="56.5" r="7.5" id="el_TZApOLwXZU" />
        </g>
        <g id="el_fIxIrV8WbF">
          <path
            d="m88.5 128v-39.4130859"
            stroke-linecap="round"
            stroke-linejoin="round"
            id="el_j5HR_U6Nrp" />
          <circle cx="88" cy="88" r="88" id="el_51c2MS41pY" />
        </g>
      </g>
    </svg>
  {/if}

  {#if type === 'pending'}
    <svg
      viewBox="0 0 190 190"
      xmlns="http://www.w3.org/2000/svg"
      id="el_XWLVvD_rP">
      <style>
        @-webkit-keyframes kf_el_fv0z90vBrL_an_PwUBZ96LS {
          0% {
            -webkit-transform: translate(88px, 87.587890625px) rotate(0deg)
              translate(-88px, -87.587890625px);
            transform: translate(88px, 87.587890625px) rotate(0deg)
              translate(-88px, -87.587890625px);
          }
          100% {
            -webkit-transform: translate(88px, 87.587890625px) rotate(360deg)
              translate(-88px, -87.587890625px);
            transform: translate(88px, 87.587890625px) rotate(360deg)
              translate(-88px, -87.587890625px);
          }
        }
        @keyframes kf_el_fv0z90vBrL_an_PwUBZ96LS {
          0% {
            -webkit-transform: translate(88px, 87.587890625px) rotate(0deg)
              translate(-88px, -87.587890625px);
            transform: translate(88px, 87.587890625px) rotate(0deg)
              translate(-88px, -87.587890625px);
          }
          100% {
            -webkit-transform: translate(88px, 87.587890625px) rotate(360deg)
              translate(-88px, -87.587890625px);
            transform: translate(88px, 87.587890625px) rotate(360deg)
              translate(-88px, -87.587890625px);
          }
        }
        @-webkit-keyframes kf_el_u3QHGLTow3_an_EQ8OetHGq {
          0% {
            -webkit-transform: translate(88px, 87.587890625px) rotate(0deg)
              translate(-88px, -87.587890625px);
            transform: translate(88px, 87.587890625px) rotate(0deg)
              translate(-88px, -87.587890625px);
          }
          50% {
            -webkit-transform: translate(88px, 87.587890625px) rotate(360deg)
              translate(-88px, -87.587890625px);
            transform: translate(88px, 87.587890625px) rotate(360deg)
              translate(-88px, -87.587890625px);
          }
          100% {
            -webkit-transform: translate(88px, 87.587890625px) rotate(720deg)
              translate(-88px, -87.587890625px);
            transform: translate(88px, 87.587890625px) rotate(720deg)
              translate(-88px, -87.587890625px);
          }
        }
        @keyframes kf_el_u3QHGLTow3_an_EQ8OetHGq {
          0% {
            -webkit-transform: translate(88px, 87.587890625px) rotate(0deg)
              translate(-88px, -87.587890625px);
            transform: translate(88px, 87.587890625px) rotate(0deg)
              translate(-88px, -87.587890625px);
          }
          50% {
            -webkit-transform: translate(88px, 87.587890625px) rotate(360deg)
              translate(-88px, -87.587890625px);
            transform: translate(88px, 87.587890625px) rotate(360deg)
              translate(-88px, -87.587890625px);
          }
          100% {
            -webkit-transform: translate(88px, 87.587890625px) rotate(720deg)
              translate(-88px, -87.587890625px);
            transform: translate(88px, 87.587890625px) rotate(720deg)
              translate(-88px, -87.587890625px);
          }
        }
        #el_XWLVvD_rP * {
          -webkit-animation-duration: 2s;
          animation-duration: 2s;
          -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
          -webkit-animation-timing-function: cubic-bezier(0, 0, 1, 1);
          animation-timing-function: cubic-bezier(0, 0, 1, 1);
        }
        #el_Uh6HOhkAVi {
          fill: none;
          stroke-width: 14;
          -webkit-transform: translate(7px, 7px);
          transform: translate(7px, 7px);
        }
        #el_PHAWgO26lN {
          stroke: #ffbd00;
        }
        #el_A4XF5QQwhp {
          stroke: #ffbf00;
        }
        #el_u3QHGLTow3_an_EQ8OetHGq {
          -webkit-animation-fill-mode: backwards;
          animation-fill-mode: backwards;
          -webkit-transform: translate(88px, 87.587890625px) rotate(0deg)
            translate(-88px, -87.587890625px);
          transform: translate(88px, 87.587890625px) rotate(0deg)
            translate(-88px, -87.587890625px);
          -webkit-animation-name: kf_el_u3QHGLTow3_an_EQ8OetHGq;
          animation-name: kf_el_u3QHGLTow3_an_EQ8OetHGq;
          -webkit-animation-timing-function: cubic-bezier(0, 0, 1, 1);
          animation-timing-function: cubic-bezier(0, 0, 1, 1);
        }
        #el_fv0z90vBrL_an_PwUBZ96LS {
          -webkit-animation-fill-mode: backwards;
          animation-fill-mode: backwards;
          -webkit-transform: translate(88px, 87.587890625px) rotate(0deg)
            translate(-88px, -87.587890625px);
          transform: translate(88px, 87.587890625px) rotate(0deg)
            translate(-88px, -87.587890625px);
          -webkit-animation-name: kf_el_fv0z90vBrL_an_PwUBZ96LS;
          animation-name: kf_el_fv0z90vBrL_an_PwUBZ96LS;
          -webkit-animation-timing-function: cubic-bezier(0, 0, 1, 1);
          animation-timing-function: cubic-bezier(0, 0, 1, 1);
        }
      </style>
      <g fill-rule="evenodd" id="el_Uh6HOhkAVi">
        <circle cx="88" cy="88" r="88" id="el_PHAWgO26lN" />
        <g stroke-linecap="round" stroke-linejoin="round" id="el_A4XF5QQwhp">
          <g
            id="el_fv0z90vBrL_an_PwUBZ96LS"
            data-animator-group="true"
            data-animator-type="1">
            <path d="m88 25v62.5878906" id="el_fv0z90vBrL" />
          </g>
          <g
            id="el_u3QHGLTow3_an_EQ8OetHGq"
            data-animator-group="true"
            data-animator-type="1">
            <path d="m88 45.9160156v41.671875" id="el_u3QHGLTow3" />
          </g>
        </g>
      </g>
    </svg>
  {/if}

  {#if type === 'success'}
    <svg
      viewBox="0 0 185 168"
      xmlns="http://www.w3.org/2000/svg"
      id="el_3OA8Szq_A">
      <style>
        @-webkit-keyframes kf_el_RzYtw9rUyN_an_gX4OsFPwzz {
          0% {
            stroke-dasharray: 473.87;
          }
          100% {
            stroke-dasharray: 473.87;
          }
        }
        @keyframes kf_el_RzYtw9rUyN_an_gX4OsFPwzz {
          0% {
            stroke-dasharray: 473.87;
          }
          100% {
            stroke-dasharray: 473.87;
          }
        }
        @-webkit-keyframes kf_el_RzYtw9rUyN_an_WfcYZ9pjL {
          0% {
            stroke-dashoffset: 473.87;
          }
          50% {
            stroke-dashoffset: 473.87;
          }
          100% {
            stroke-dashoffset: 0;
          }
        }
        @keyframes kf_el_RzYtw9rUyN_an_WfcYZ9pjL {
          0% {
            stroke-dashoffset: 473.87;
          }
          50% {
            stroke-dashoffset: 473.87;
          }
          100% {
            stroke-dashoffset: 0;
          }
        }
        #el_3OA8Szq_A * {
          -webkit-animation-duration: 1s;
          animation-duration: 1s;
          -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
          -webkit-animation-timing-function: cubic-bezier(0, 0, 1, 1);
          animation-timing-function: cubic-bezier(0, 0, 1, 1);
        }
        #el_RzYtw9rUyN {
          fill: none;
          stroke: #7ed321;
          stroke-width: 17;
          -webkit-animation-fill-mode: forwards, forwards;
          animation-fill-mode: forwards, forwards;
          -webkit-animation-name: kf_el_RzYtw9rUyN_an_WfcYZ9pjL,
            kf_el_RzYtw9rUyN_an_gX4OsFPwzz;
          animation-name: kf_el_RzYtw9rUyN_an_WfcYZ9pjL,
            kf_el_RzYtw9rUyN_an_gX4OsFPwzz;
          -webkit-animation-timing-function: cubic-bezier(0, 0, 1, 1),
            cubic-bezier(0, 0, 1, 1);
          animation-timing-function: cubic-bezier(0, 0, 1, 1),
            cubic-bezier(0, 0, 1, 1);
        }
      </style>
      <path
        d="m176.126953 63.8789062-94.4130858 95.4130858-72.87402345-72.8740232
        27.93945315-27.9394532 44.9345703 44.9345704 94.4130858-94.413086"
        stroke-linecap="round"
        stroke-linejoin="round"
        id="el_RzYtw9rUyN" />
    </svg>
  {/if}

  {#if type === 'error'}
    <svg
      viewBox="0 0 178 178"
      xmlns="http://www.w3.org/2000/svg"
      id="el_bYTVKD04y">
      <style>
        @-webkit-keyframes kf_el_IAuv9ut-2-_an_xlDuvYsRc {
          50% {
            opacity: 0;
          }
          66.67% {
            opacity: 1;
          }
          0% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }
        @keyframes kf_el_IAuv9ut-2-_an_xlDuvYsRc {
          50% {
            opacity: 0;
          }
          66.67% {
            opacity: 1;
          }
          0% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }
        @-webkit-keyframes kf_el_IAuv9ut-2-_an_29XE36SGo1 {
          50% {
            stroke-dasharray: 39.41;
          }
          0% {
            stroke-dasharray: 39.41;
          }
          100% {
            stroke-dasharray: 39.41;
          }
        }
        @keyframes kf_el_IAuv9ut-2-_an_29XE36SGo1 {
          50% {
            stroke-dasharray: 39.41;
          }
          0% {
            stroke-dasharray: 39.41;
          }
          100% {
            stroke-dasharray: 39.41;
          }
        }
        @-webkit-keyframes kf_el_IAuv9ut-2-_an_xo_EIWruT {
          50% {
            stroke-dashoffset: 39.41;
          }
          100% {
            stroke-dashoffset: 0;
          }
          0% {
            stroke-dashoffset: 39.41;
          }
        }
        @keyframes kf_el_IAuv9ut-2-_an_xo_EIWruT {
          50% {
            stroke-dashoffset: 39.41;
          }
          100% {
            stroke-dashoffset: 0;
          }
          0% {
            stroke-dashoffset: 39.41;
          }
        }
        @-webkit-keyframes kf_el_q_eIK0z3HI_an_045tZJOHl {
          50% {
            -webkit-transform: translate(88.50000762939453px, 144.5px)
              scale(0, 0) translate(-88.50000762939453px, -144.5px);
            transform: translate(88.50000762939453px, 144.5px) scale(0, 0)
              translate(-88.50000762939453px, -144.5px);
          }
          83.33% {
            -webkit-transform: translate(88.50000762939453px, 144.5px)
              scale(0, 0) translate(-88.50000762939453px, -144.5px);
            transform: translate(88.50000762939453px, 144.5px) scale(0, 0)
              translate(-88.50000762939453px, -144.5px);
          }
          100% {
            -webkit-transform: translate(88.50000762939453px, 144.5px)
              scale(1, 1) translate(-88.50000762939453px, -144.5px);
            transform: translate(88.50000762939453px, 144.5px) scale(1, 1)
              translate(-88.50000762939453px, -144.5px);
          }
          0% {
            -webkit-transform: translate(88.50000762939453px, 144.5px)
              scale(0, 0) translate(-88.50000762939453px, -144.5px);
            transform: translate(88.50000762939453px, 144.5px) scale(0, 0)
              translate(-88.50000762939453px, -144.5px);
          }
        }
        @keyframes kf_el_q_eIK0z3HI_an_045tZJOHl {
          50% {
            -webkit-transform: translate(88.50000762939453px, 144.5px)
              scale(0, 0) translate(-88.50000762939453px, -144.5px);
            transform: translate(88.50000762939453px, 144.5px) scale(0, 0)
              translate(-88.50000762939453px, -144.5px);
          }
          83.33% {
            -webkit-transform: translate(88.50000762939453px, 144.5px)
              scale(0, 0) translate(-88.50000762939453px, -144.5px);
            transform: translate(88.50000762939453px, 144.5px) scale(0, 0)
              translate(-88.50000762939453px, -144.5px);
          }
          100% {
            -webkit-transform: translate(88.50000762939453px, 144.5px)
              scale(1, 1) translate(-88.50000762939453px, -144.5px);
            transform: translate(88.50000762939453px, 144.5px) scale(1, 1)
              translate(-88.50000762939453px, -144.5px);
          }
          0% {
            -webkit-transform: translate(88.50000762939453px, 144.5px)
              scale(0, 0) translate(-88.50000762939453px, -144.5px);
            transform: translate(88.50000762939453px, 144.5px) scale(0, 0)
              translate(-88.50000762939453px, -144.5px);
          }
        }
        @-webkit-keyframes kf_el_5BNAI_PBsn_an_aToWhdlG8F {
          50% {
            stroke-dasharray: 527.67;
          }
          0% {
            stroke-dasharray: 527.67;
          }
          100% {
            stroke-dasharray: 527.67;
          }
        }
        @keyframes kf_el_5BNAI_PBsn_an_aToWhdlG8F {
          50% {
            stroke-dasharray: 527.67;
          }
          0% {
            stroke-dasharray: 527.67;
          }
          100% {
            stroke-dasharray: 527.67;
          }
        }
        @-webkit-keyframes kf_el_5BNAI_PBsn_an_tQV_CQebU {
          50% {
            stroke-dashoffset: 527.67;
          }
          100% {
            stroke-dashoffset: 0;
          }
          0% {
            stroke-dashoffset: 527.67;
          }
        }
        @keyframes kf_el_5BNAI_PBsn_an_tQV_CQebU {
          50% {
            stroke-dashoffset: 527.67;
          }
          100% {
            stroke-dashoffset: 0;
          }
          0% {
            stroke-dashoffset: 527.67;
          }
        }
        #el_bYTVKD04y * {
          -webkit-animation-duration: 1s;
          animation-duration: 1s;
          -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
          -webkit-animation-timing-function: cubic-bezier(0, 0, 1, 1);
          animation-timing-function: cubic-bezier(0, 0, 1, 1);
        }
        #el_doMgf96Cxx {
          fill: none;
          -webkit-transform: translate(1px, -5px);
          transform: translate(1px, -5px);
        }
        #el_5BNAI_PBsn {
          stroke: #ff0039;
          stroke-width: 14;
          -webkit-animation-fill-mode: forwards, forwards;
          animation-fill-mode: forwards, forwards;
          -webkit-animation-name: kf_el_5BNAI_PBsn_an_tQV_CQebU,
            kf_el_5BNAI_PBsn_an_aToWhdlG8F;
          animation-name: kf_el_5BNAI_PBsn_an_tQV_CQebU,
            kf_el_5BNAI_PBsn_an_aToWhdlG8F;
          -webkit-animation-timing-function: cubic-bezier(0, 0, 1, 1),
            cubic-bezier(0, 0, 1, 1);
          animation-timing-function: cubic-bezier(0, 0, 1, 1),
            cubic-bezier(0, 0, 1, 1);
        }
        #el_q_eIK0z3HI {
          fill: #ff0042;
        }
        #el_IAuv9ut-2- {
          stroke: #ff0042;
          stroke-width: 14;
          -webkit-animation-fill-mode: forwards, forwards, forwards;
          animation-fill-mode: forwards, forwards, forwards;
          -webkit-animation-name: kf_el_IAuv9ut-2-_an_xo_EIWruT,
            kf_el_IAuv9ut-2-_an_29XE36SGo1, kf_el_IAuv9ut-2-_an_xlDuvYsRc;
          animation-name: kf_el_IAuv9ut-2-_an_xo_EIWruT,
            kf_el_IAuv9ut-2-_an_29XE36SGo1, kf_el_IAuv9ut-2-_an_xlDuvYsRc;
          -webkit-animation-timing-function: cubic-bezier(0, 0, 1, 1),
            cubic-bezier(0, 0, 1, 1), cubic-bezier(0, 0, 1, 1);
          animation-timing-function: cubic-bezier(0, 0, 1, 1),
            cubic-bezier(0, 0, 1, 1), cubic-bezier(0, 0, 1, 1);
        }
        #el_q_eIK0z3HI_an_045tZJOHl {
          -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
          -webkit-animation-name: kf_el_q_eIK0z3HI_an_045tZJOHl;
          animation-name: kf_el_q_eIK0z3HI_an_045tZJOHl;
          -webkit-animation-timing-function: cubic-bezier(0, 0, 1, 1);
          animation-timing-function: cubic-bezier(0, 0, 1, 1);
        }
      </style>
      <g fill-rule="evenodd" id="el_doMgf96Cxx">
        <path
          d="m96.9442719 17.8885438 71.8196601 143.6393202c2.469893
          4.939785.467649 10.946515-4.472136 13.416408-1.388554.694277-2.919685
          1.055728-4.472136 1.055728h-143.6393201c-5.5228475
          0-10.00000001-4.477153-10.00000001-10 0-1.552451.36145092-3.083582
          1.05572809-4.472136l71.81966012-143.6393202c2.4698925-4.939785
          8.4766229-6.9420284 13.4164079-4.4721359 1.935274.967637 3.5044989
          2.5368619 4.4721359 4.4721359z"
          stroke-linejoin="round"
          id="el_5BNAI_PBsn" />
        <g
          id="el_q_eIK0z3HI_an_045tZJOHl"
          data-animator-group="true"
          data-animator-type="2">
          <circle cx="88.5" cy="144.5" r="7.5" id="el_q_eIK0z3HI" />
        </g>
        <path
          d="m88.5 112.413086v-39.413086"
          stroke-linecap="round"
          stroke-linejoin="round"
          id="el_IAuv9ut-2-" />
      </g>
    </svg>
  {/if}
</div>
